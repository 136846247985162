/* ============================================================================
   Abstract: Colors
   ========================================================================= */

/**
 * Grey
 */
$color-athens-gray: grayscale(#f0f3f5);
$color-gray-chateau: grayscale(#a4a8ab);
$color-river-bed: grayscale(#46535b);
$color-grey: grayscale(#8a8d99);
$color-iron: grayscale(#dde1e4);
$color-iron-dark: darken($color-iron, 6%);
$color-iron-x-dark: darken($color-iron, 14%);
$color-porcelain: grayscale(#ebeef0);
$color-porcelain-dark: darken($color-porcelain, 8%);
$color-cutty-sark: grayscale(#57707a);
$color-cutty-sark-dark: darken($color-cutty-sark, 8%);
$color-oslo-gray: grayscale(#93979a);
$color-oslo-gray-dark: darken($color-oslo-gray, 8%);


/* ============================================================================
   Abstract: Variables
   ========================================================================= */

/**
 * Base
 */
$base-font-family: 'Open Sans', sans-serif;
$iframe-fullsize: true;

/**
 * Font Weights
 */
$extra-light: 300;

/**
 * Main Color Variables
 */
$color-primary: #bd0b20;
$color-primary-hover: darken($color-primary, 8%);

/**
 * Colors with specified element
 */
$color-headline-color: $color-primary;
$color-link-color: $color-primary;
$color-link-hover-color: $color-primary-hover;

/**
 * Nav
 */
$flNavEl1-here-border: 2px solid $color-primary;

/**
 * Tabs
 */
$tabs-nav-item-hover-border-bottom: 2px solid $color-primary;
$tabs-nav-item-current-border-bottom: 2px solid $color-primary;

/**
 * Component: Panel
 */

$panel-blue-background: $color-primary;
$panel-orange-background: $color-grey;
$panel-cyan-background: $color-grey;
$panel-pink-background: $color-grey;

/**
 * Component: Panel
 */
$button-category-cyan-background: $color-primary;
$button-category-cyan-hover-background: $color-primary-hover;

$button-category-pink-background: $color-primary;
$button-category-pink-hover-background: $color-primary-hover;

$button-category-orange-background: $color-primary;
$button-category-orange-hover-background: $color-primary-hover;

/**
 * Component: Logo
 */
$logo-head-height: rem-calc(46px);
$logo-head-top: 12px;

/**
 * Component: Embed
 */
$embed-preview-disable: true;

/**
 * Component: Popup
 */
$popup-iframeholder-max-width: 1200px;


/* ============================================================================
   Base: Fonts
   ========================================================================= */

@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600);


/* ============================================================================
   Page: MyLivingApps Overview
   ========================================================================= */

$mylivingapps-overview-panel-headline-overflow: true;


/* ============================================================================
   Custom CSS
   ========================================================================= */

.stdSty.extra-links {
	position: absolute;
	left: 260px;
	top: 12px;
}

.flOuterShell_floats #jsFlNav1.nav_1 {
	margin-left: 410px;
}

@media only screen and (max-width: 959px) {
   .stdSty.extra-links {
      left: 200px;
      top: 8px;

      img {
         max-height: 40px;
         width: auto;
      }
   }

   .flOuterShell_floats #jsFlNav1.nav_1 {
   margin-left: 0;
   }
}